<template>
  <v-container>
    <v-row class="align-center">
      <v-col cols="12" md="auto" class="ma-n2">
        <router-link to="/"><v-btn color="primary" dark class="ma-2"><v-icon left dark >mdi-keyboard-backspace</v-icon >Назад к обзвонам</v-btn></router-link>
        <router-link :to="{ name: 'contactsList' }"><v-btn color="primary" dark class="ma-2"><v-icon left dark >mdi-playlist-edit</v-icon>Список контактов</v-btn></router-link>
      </v-col>

      <v-col class="flex-grow-1 pl-4">
        <v-text-field
          placeholder="Поиск"
          solo
          append-icon="mdi-magnify"
          hide-details="auto"
          v-model="search"
        />
      </v-col>
    </v-row>

    <div class="mt-3">
      <v-row>
        <v-col>
          <v-data-table
            ref="table"
            :headers="headers"
            :items="filteredGroups"
            :loading="IS_LOADING_GROUPS"
            loading-text="Загрузка... Пожалуйста подождите"
            class="elevation-2"
            :footer-props="{
              itemsPerPageOptions: [50,100,-1],
              showFirstLastPage: true,
              firstIcon: 'mdi-chevron-double-left',
              lastIcon: 'mdi-chevron-double-right',
              prevIcon: 'mdi-chevron-left',
              nextIcon: 'mdi-chevron-right'
            }"
          >
            <template v-slot:item.name="{ item }">
              <v-edit-dialog
                  :return-value.sync="item.name"
                  @save="updateName(item.id)"
                  @open="editName = item.name"
              >
                {{ item.name }}
                <template v-slot:input>
                  <v-text-field dense v-model="editName">
                    <template v-slot:append>
                      <v-btn dark icon color="primary"><v-icon>mdi-content-save</v-icon></v-btn>
                    </template>
                  </v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:item.actions="{ item }">
              <div class="d-inline-flex">
                <v-btn icon color="red darken-3" class="ml-1" dark @click.stop="deleteGroup(item.id)"><v-icon>mdi-delete</v-icon></v-btn>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Groups',
  data() {
    return {
      search: '',

      editName: '',

      headers: [
        { text: 'Название', align: 'start', value: 'name' },
        { align: 'right', value: 'actions' },
      ],
    };
  },
  computed: {
    ...mapGetters(['GROUPS', 'IS_LOADING_GROUPS']),
    filteredGroups() {
      return this.GROUPS
        ? (
          !this.search
            ? this.GROUPS
            : this.GROUPS.filter(group => (~group.name.toLocaleLowerCase().indexOf(this.search.toLocaleLowerCase())))
        )
        : [];
    },
  },
  methods: {
    ...mapActions(['UPDATE_NAME_GROUP', 'DELETE_GROUP']),
    updateName(id) {
      this.UPDATE_NAME_GROUP({ id, name: this.editName })
        .then(() => { this.$notify({ text: 'Название группы обновлено', type: 'success' }); })
        .catch(() => { this.$notify({ text: 'Не удалось обновить название', type: 'error' }); });
    },
    deleteGroup(groupId) {
      this.DELETE_GROUP(groupId)
        .then(() => { this.$notify({ text: 'Группа удалена', type: 'success' }); })
        .catch(() => { this.$notify({ text: 'Не удалось удалить группу', type: 'error' }); });
    },
  },
};
</script>
