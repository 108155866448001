var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"ma-n2",attrs:{"cols":"12","md":"auto"}},[_c('router-link',{attrs:{"to":"/"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","dark":""}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-keyboard-backspace")]),_vm._v("Назад к обзвонам")],1)],1),_c('router-link',{attrs:{"to":{ name: 'contactsList' }}},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","dark":""}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-playlist-edit")]),_vm._v("Список контактов")],1)],1)],1),_c('v-col',{staticClass:"flex-grow-1 pl-4"},[_c('v-text-field',{attrs:{"placeholder":"Поиск","solo":"","append-icon":"mdi-magnify","hide-details":"auto"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('div',{staticClass:"mt-3"},[_c('v-row',[_c('v-col',[_c('v-data-table',{ref:"table",staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.filteredGroups,"loading":_vm.IS_LOADING_GROUPS,"loading-text":"Загрузка... Пожалуйста подождите","footer-props":{
            itemsPerPageOptions: [50,100,-1],
            showFirstLastPage: true,
            firstIcon: 'mdi-chevron-double-left',
            lastIcon: 'mdi-chevron-double-right',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right'
          }},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_c('v-edit-dialog',{attrs:{"return-value":item.name},on:{"update:returnValue":function($event){return _vm.$set(item, "name", $event)},"update:return-value":function($event){return _vm.$set(item, "name", $event)},"save":function($event){return _vm.updateName(item.id)},"open":function($event){_vm.editName = item.name}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{attrs:{"dark":"","icon":"","color":"primary"}},[_c('v-icon',[_vm._v("mdi-content-save")])],1)]},proxy:true}],null,true),model:{value:(_vm.editName),callback:function ($$v) {_vm.editName=$$v},expression:"editName"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.actions",fn:function({ item }){return [_c('div',{staticClass:"d-inline-flex"},[_c('v-btn',{staticClass:"ml-1",attrs:{"icon":"","color":"red darken-3","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteGroup(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }